import { Routes, Route } from 'react-router-dom';
import PageNotFound from '../pages/PageNotFound';
import Home from '../pages/Home';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';

const PickadonRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="terms" element={<TermsAndConditionsPage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default PickadonRoutes;
