import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import Navigationbar from './navigation/Navigationbar';
import PickadonRoutes from './navigation/PickadonRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navigationbar />
        <Container
          fluid
          style={{
            maxWidth: '1000px',
            marginTop: '2rem',
            color: '#312B25',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Row className="p-0" style={{ marginLeft: 0, marginRight: 0 }}>
            <PickadonRoutes />
          </Row>
        </Container>
      </BrowserRouter>
    </div>
  );
}

export default App;
