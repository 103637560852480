import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { IoLogoGooglePlaystore, IoLogoApple } from 'react-icons/io5';

const Home = () => {
  return (
    <Col style={{ textAlign: 'center' }}>
      <h1>Pickadon</h1>
      <p dir="rtl">
        {
          'אפליקציית פיקדון מיועדת לקשר בין אלו הרוצים למסור בקבוקים לבין אלו המעוניינים לאוספם ולהרוויח קצת כסף.'
        }{' '}
        {
          'מסירת בקבוקים הינה פעולה פשוטה: רק להניח את הבקבוקים בפתח הבית וללחוץ על כפתור המסירה.'
        }
      </p>
      <p dir="rtl">
        {
          'אם רוצים לאסוף בקבוקים, כל מה שצריך זה לפתוח את האפליקציה ולגשת למפת האיסוף ולראות את נקודות האיסוף סביבך, לגשת לאסוף ולסמן שהנקודה נאספה.'
        }
      </p>
      <p dir="rtl">
        {
          'יש להפעיל את שירותי המיקום, כדי לאפשר פעולה תקינה של האפליקציה. אנחנו לא אוספים כל מידע אישי או מיקומים לפי משתמשים.'
        }
      </p>
      <p>
        {
          'THE APP WHERE YOU CAN DROP YOUR PICKADON, MAKE SOMEONE ELSE HAPPY AND MAKE THE WORLD A LITTLE BIT BETTER'
        }
      </p>
      <p>
        {'PICKADON IS THE NAME FOR THE BOTTLE DEPOSIT, THAT YOU GET BACK WHEN RETURNING YOUR BOTTLES. TO MAKE IT EASIER FOR EVERYONE. THIS APP MAKES IT POSSIBLE TO DROP YOUR BOTTLES OUTSIDE YOUR ' +
          'FRONTDOOR, PUT IN THE DROP LOCATION IN THE APP AND SOMEONE ELSE WILL PICK THEM UP AND EARN THE MONEY OR YOU CAN ENTER THE APP, SEE WHERE THERE ARE BOTTLES WAITING FOR A PICK-UP AND COLLECT'}
      </p>
      <p>
        {
          'We are not collecting you location nor any private data, we just matching between drop points to people who wish to collect bottles'
        }
      </p>
      <p>
        {
          'We believe this app can make bottles recycle more efficient and that less plastic will end up in our streets and oceans.'
        }
      </p>
      <p>
        {
          'In order for this to work, we need you to report accurately both on dropping and picking bottles'
        }
      </p>
      <p>
        <a href="https://play.google.com/store/apps/details?id=com.pickadon">
          <IoLogoGooglePlaystore
            color={'#ED9506'}
            size={44}
            title="Pickadon app in google store"
            alt="Pickadon app in google store"
          />
        </a>
        <span style={{ padding: '0 40px' }} />
        <a href="https://apps.apple.com/app/id6451062943">
          <IoLogoApple
            color={'#ED9506'}
            size={44}
            title="Pickadon app in the app store"
            alt="Pickadon app in the app store"
          />
        </a>
      </p>
      <p>
        {'In any questions or suggestions, please '}
        <a href="mailto:info@pickadon.com">email us</a>
      </p>
    </Col>
  );
};

export default Home;
