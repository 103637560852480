import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import logo from '../assets/applogo4.png';
import { GoMail } from 'react-icons/go';
import { SiInstagram ,SiTwitter } from 'react-icons/si';
// import Logo from '../../assets/images/Logo';

import classes from './Navigationbar.module.css';

const Navigationbar = () => {
  const location = useLocation();
  useEffect(() => {
    const paths = location.pathname.split('/');
    let t = 'Pickadon';
    paths.forEach((p) => {
      if (p) {
        t = `${t} | ${p}`;
      }
    });
    document.title = t;
    console.log(`/${location.pathname.split('/')[1]}`);
  }, [location.pathname, location.search]);

  return (
    <Navbar
      collapseOnSelect
      className="color-nav"
      expand="lg"
      bg=""
      variant="light"
    >
      <Container>
        <Navbar.Brand className={classes.Brand} as={Link} to="/">
          <img src={logo} alt={'logo'} style={{ width: '60px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            activeKey={`/${location.pathname.split('/')[1]}`}
            className="me-auto"
          >
            <Nav.Link as={Link} to="/" eventKey="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/terms" eventKey="/terms">
              Terms of use
            </Nav.Link>
          </Nav>
          <Nav activeKey={location.pathname}>
            <Nav.Link href="mailto:info@pickadon.com" eventKey="mail">
              <GoMail size={24} />
            </Nav.Link>
            <Nav.Link href="https://instagram.com/_pickadon" target={"_blank"} eventKey="mail">
              <SiInstagram size={20} />
            </Nav.Link>
            <Nav.Link href="https://twitter.com/pickadon" target={"_blank"} eventKey="mail">
              <SiTwitter size={24} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigationbar;
